import '../App.css';

function Footer() {
  return (
    <div className="Footer">
    </div>
  );
}

export default Footer;
