import '../App.css';

import Resume from '../files/stephanie-chiao_resume.pdf';

function Menu(props) {
  return (
    <div id={props.title} className="Menu">
      <h2 id="menu-stephanie">Stephanie Chiao</h2>

      <div id="menu-socials">
      	{/*<h2 id="email-link">Email</h2>*/}
      	<h2 id="linkedin-link">
      		<a href="https://www.linkedin.com/in/stephchiao/">Linkedin</a>
      	</h2>
      	<h2 id="resume-link">
      		<a href={Resume} target="_blank">Resume</a>
      	</h2>
      </div>


    </div>
  );
}

export default Menu;
