import React, { useState } from 'react';

import logo from './logo.svg';
import './App.css';
import Content from './components/Content.js'
import Menu from './components/Menu.js'
import Footer from './components/Footer.js'
import DarkModeToggle from './components/DarkModeToggle.js'
import MousePanelToggle from './components/MousePanelToggle';
import MousePanel from './components/MousePanel';
import { useKonamiCode } from './hooks/useKonamiCode';

function App() {

  const [darkMode, setDarkMode] = useState(true);
  const konami = useKonamiCode();
  const [mousePanelOpen, setMousePanelOpen] = useState(false)

  return (
    <div className={`App ${darkMode ? "dark-mode" : "light-mode"}`} >
      <Content />

      <Menu />

      {/* {konami && <p>hiiiii</p>} */}

      <div className="toggles">
        {konami && 
          <>
            <MousePanelToggle mousePanelOpen={mousePanelOpen} toggleMousePanel={() => setMousePanelOpen(!mousePanelOpen)} />
            {/* <MousePanel mousePanelOpen={mousePanelOpen}/> */}
            {mousePanelOpen && <MousePanel />}
          </>
        }
        <DarkModeToggle darkMode={darkMode} toggleDarkMode={() => setDarkMode(!darkMode)}/>
      </div>

      <Footer />
    </div>
  );
}

export default App;
