import '../App.css';

function MousePanel(props) {
  return (
    <div className='MousePanel'>
        
    </div>
  );
}

export default MousePanel;
