import '../App.css';

function Section(props) {
  return (
    <div id={props.title} className="Section">
      <h2>{props.title}</h2>

      {props.children}
    </div>
  );
}

export default Section;
