import '../App.css';

function DarkModeToggle(props) {
  return (
    <div className={`DarkModeToggle ${props.darkMode ? "dark-mode" : "light-mode"}`}>
      {/* <div className="pill" onClick={props.toggleDarkMode}>
        <div className="switch">
          <div className="icon-container">
            <svg className="moon" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width=".8rem" height=".8rem" fill="#171717"><path d="M14,24A12.013,12.013,0,0,1,2,12C1.847,3.044,12.031-2.985,19.791,1.509l1.553.862-1.543.88c-6.7,3.688-6.21,13.87.8,16.906l1.621.731-1.467,1.006A11.921,11.921,0,0,1,14,24Z"/></svg>
            <svg className="sun" xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width=".8rem" height=".8rem" fill="#F9F7F3"><path d="M22.5,10.5H19.349a7.455,7.455,0,0,0-1.1-2.632l2.232-2.232a1.5,1.5,0,0,0-2.121-2.121L16.132,5.747a7.455,7.455,0,0,0-2.632-1.1V1.5a1.5,1.5,0,0,0-3,0V4.651a7.455,7.455,0,0,0-2.632,1.1L5.636,3.515A1.5,1.5,0,0,0,3.515,5.636L5.747,7.868a7.455,7.455,0,0,0-1.1,2.632H1.5a1.5,1.5,0,0,0,0,3H4.651a7.455,7.455,0,0,0,1.1,2.632L3.515,18.364a1.5,1.5,0,0,0,2.121,2.121l2.232-2.232a7.455,7.455,0,0,0,2.632,1.1V22.5a1.5,1.5,0,0,0,3,0V19.349a7.455,7.455,0,0,0,2.632-1.1l2.232,2.232a1.5,1.5,0,0,0,2.121-2.121l-2.232-2.232a7.455,7.455,0,0,0,1.1-2.632H22.5A1.5,1.5,0,0,0,22.5,10.5ZM12,16.5a4.5,4.5,0,0,1,0-9A4.5,4.5,0,0,1,12,16.5Z"/></svg>
          </div>

        </div>
      </div> */}
        <svg className="half-moon" onClick={props.toggleDarkMode} xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="1rem" height="1rem"><path d="M12,0C5.38,0,0,5.38,0,12s5.38,12,12,12,12-5.38,12-12S18.62,0,12,0Zm0,22V2c5.51,0,10,4.49,10,10s-4.49,10-10,10Z"/></svg>


    </div>
  );
}

export default DarkModeToggle;
