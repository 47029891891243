import '../App.css';

function ListItem(props) {
  return (
    <div className="ListItem">
      <p>
      	<div>{props.company}</div>
      	<div className="subtext">{props.subtext}</div>
      </p>
    </div>
  );
}

export default ListItem;
