import '../App.css';
import Section from './Section.js'
import ListItem from './ListItem.js'

import Resume from '../files/stephanie-chiao_resume.pdf';

function Content() {
  return (
    <div className="Content">

      <Section title="Introduction"> 
        <h1>Stephanie Chiao —</h1>
        <h1 id="intro-sub">Software Developer in Boston.</h1>
      </Section>
      <Section title="About">
          <p>
            Hello! I’m a full stack software developer working remotely in Boston.
          </p>
          {/*<p>
            Hello! I’m a full stack software developer working remotely in Boston.
            Currently at <span class="underline">Realtor.com 🏠</span>.
          </p>
          */}
          {/* <p>
            Hello! I’m a full stack software developer working remotely in Boston.
            Currently at Realtor.com 🏠.
          </p> */}
          {/* <p>
            Hello! I’m a full stack software developer working remotely in Boston.
            Currently at <a href="https://apps.apple.com/us/app/opcity/id1232078017">Opcity</a> at <a href="https://www.realtor.com/marketing/opcity/">Realtor.com 🏠</a>.
          </p> */}
          

          <p>
            I'm a big fan of petting my dog, desserts, 
            pasta, and sometimes video games.
          </p>
      </Section>
      <Section title="Work">
        <ListItem company="Realtor.com &nbsp;🏠" subtext="2022-2024"/>
        <ListItem company="Expedia &nbsp;🏨" subtext="2019-2022"/>
        <ListItem company="Amazon &nbsp;📦" subtext="2017-2018"/>
        <ListItem company="Amazon Game Studios &nbsp;🎮" subtext="internship, 2016 "/>
      </Section>
      <Section title="Skills"> 
        <ListItem 
          company="Frontend" 
          subtext="React, JavaScript, Node, TypeScript, HTML, CSS"/>
        <ListItem 
          company="Backend" 
          subtext="Java, Python, Spring, GraphQL, DynamoDB, SQL"/>
        <ListItem 
          company="Other" 
          subtext="Staying positive"/>
      </Section>
      <Section title="More"> 
        
        <div>
          <ListItem company="Boston University"   subtext="2017" />
        </div>

        <div>
          <p>💌 chiaosteph@gmail.com</p>
          <p>
            <a href="https://www.linkedin.com/in/stephchiao/">Linkedin</a>
            &nbsp;&&nbsp;
            <a href={Resume} target="_blank">resume</a>
          </p>
        </div>

        {/*
        <div>
          <p>🥳</p>
        </div>
        */}
      </Section>
      
    </div>
  );
}

export default Content;
