import '../App.css';

function MousePanelToggle(props) {
  return (
    <div className={`MousePanelToggle ${props.mousePanelOpen ? "mouse-panel-open" : "mouse-panel-closed"}`} onClick={props.toggleMousePanel}>
        {props.mousePanelOpen ? 
            <svg className="mouse-close" xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa" viewBox="0 0 512.021 512.021"   width="1rem" height="1rem">
                <g>
                    <path d="M301.258,256.01L502.645,54.645c12.501-12.501,12.501-32.769,0-45.269c-12.501-12.501-32.769-12.501-45.269,0l0,0   L256.01,210.762L54.645,9.376c-12.501-12.501-32.769-12.501-45.269,0s-12.501,32.769,0,45.269L210.762,256.01L9.376,457.376   c-12.501,12.501-12.501,32.769,0,45.269s32.769,12.501,45.269,0L256.01,301.258l201.365,201.387   c12.501,12.501,32.769,12.501,45.269,0c12.501-12.501,12.501-32.769,0-45.269L301.258,256.01z"/>
                </g>
            </svg>
            :
            <svg className="mouse-open" xmlns="http://www.w3.org/2000/svg" id="Filled" viewBox="0 0 24 24" width="1rem" height="1rem"><path d="M13,3V1a1,1,0,0,0-2,0V3a7.008,7.008,0,0,0-7,7v6a8,8,0,0,0,16,0V10A7.008,7.008,0,0,0,13,3Zm0,7a1,1,0,0,1-2,0V8a1,1,0,0,1,2,0Z"/></svg>
        }

    </div>
  );
}

export default MousePanelToggle;
